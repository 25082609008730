
import React from 'react';
import { useHistory } from 'react-router-dom';
import ConfirmButton from 'src/pages/Adtopic/component/ConfirmButton';
import styles from './index.module.less';

const BuyModal: React.FC = (props) => {
  const { children } = props;
  const history = useHistory();

  const goToBuy = () => {
    history.push('/pricing/');
  };

  return (
    <div className={styles.maskContent}>
      <div className={styles.maskWarpper} />
      <div className={styles.maskContainer}>
        <div className={styles.content}>
          <p className={styles.title}>
            免费版账号暂无权限使用，
          </p>
          <p className={styles.title}>
            请升级至高级版账号。
          </p>
        </div>
        <div className={styles.bottom}>
          <ConfirmButton
            onClick={goToBuy}
            className={styles.goBuy}
            text="前往购买"
          />
        </div>
      </div>
      {children}
    </div>
  );
};

export default BuyModal;
